// import * as TYPES from 'actions/types';

// const INITIAL_STATE = {
//   episode: null, // Initialize episode to null
//   cast: [], // Initialize cast array
//   loading: true
// };

// const episodeReducer = (state = INITIAL_STATE, action) => {
//   switch (action.type) {
//     case TYPES.FETCH_EPISODES:
//       return { ...state, episode: action.payload }; // Update episode
//     case TYPES.FETCH_CREDITS:
//       return { ...state, cast: action.payload }; // Update cast array
//     case TYPES.SET_EPISODE_LOADING:
//       return { ...state, loading: true }; // Set loading to true
//     case TYPES.UNSET_EPISODE_LOADING:
//       return { ...state, loading: false }; // Set loading to false
//     default:
//       return state;
//   }
// };

// export default episodeReducer;
